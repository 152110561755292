window.jQuery = window.$ = require('jquery');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle');

$(document).ready(function() {
    $('#addCollectionDataRow').click(function () {
        let products = $('.collection-data-rows').attr('data-count');
        let count = $('.collection-data-rows .collection-data-row').length;

        $('.collection-empty-data-row').clone().addClass('collection-current-data-row').appendTo(".collection-data-rows");
        let $row = $('.collection-current-data-row');
        $row.removeClass('collection-empty-data-row').removeClass('d-none').removeClass('collection-current-data-row').addClass("collection-data-row").addClass("collection-added-data-row");
        renumberRow($row);
    });
    $("body").on("click", ".removeCollectionDataRow", function () {
        $(this).parents(".collection-data-row").remove();
        $(".collection-data-rows .collection-added-data-row").each(function () {
            renumberRow($(this));
        });
    });
});

function renumberRow($row) {
    console.log($row);
    let number = $(".collection-data-rows .collection-data-row").index($row);
    $row.find('.student-number').text(number);
    $row.find('label[for*=name]').attr("for", "name" + number);
    $row.find('input[id*=name]').attr("id", "name" + number).attr("name", "tableAdded[" + number + "][name]").attr('required', 'required');
}
